


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
export default function Button(props) {
    const {children, text, symbol} = props;


    return (
        <button onClick={() => window.open("https://www.skool.com/truthcommunity/about")} className="w-[250px] h-[100%] bg-gradient-to-tr from-red-400/20 via-orange-300/20 to-pink-400/20 
        border-x-8 border-white   text-white text-4xl  hover:bg-white/10 transition-all duration-250
        flex justify-center items-center relative
        ">
            {text}
            <FontAwesomeIcon icon={faChevronRight} className="absolute top-[50%] -translate-y-1/2 right-[5%]"></FontAwesomeIcon>
        </button>
    
    )
}