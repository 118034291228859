import React, { useEffect } from 'react';
import { useCallback, useState } from "react";
import Clicker from './Old/Clicker';
import Panel from './Old/Panel';
import Card from './Card';

export default function Carousel(props) {
    const {children, elements} = props;
    let maxPages = elements.length;

    const [currentPage, setCurrentPage] = useState(0);

    
    const changePage = useCallback((index) => {

        if (index === currentPage - 1 ) {
            if (currentPage === 0) {
                setCurrentPage(maxPages-1)
            } else {
                setCurrentPage(currentPage-1)
            }
        } else if (index === currentPage + 1){
            if (currentPage === maxPages-1) {
                setCurrentPage(0)
            } else {
                setCurrentPage(currentPage+1)
            }
        } else {
            setCurrentPage(index)
        }
    },[currentPage, maxPages]);

    const mappedChildren = elements.map( (ele, index) => (
        <div 
            key={index}
            onClick={() => changePage(index)}
            className={`w-full flex items-center justify-center 
            ${ currentPage === index || currentPage === index - 1 || currentPage === index + 1 || (currentPage === 0 && index === maxPages - 1) || (currentPage === maxPages - 1 && index === 0) ? "flex" : "hidden"} 
            ${ currentPage === index ? "w-[60%]" : "w-[20%] cursor-pointer  "} 
        `}>
            
            <Card focused={currentPage === index} name={elements[index].name} quote={elements[index].quote} link={elements[index].link}>

            </Card>


        </div>
        )
      );
    
    // const orderPages = useCallback(() => {

    // }, [mappedChildren])

    const reorderChildren = useCallback(() => {

        if (currentPage === 0) {
            console.log("Put end element at beginning.");
            let endElement = mappedChildren.pop()
            mappedChildren.unshift(endElement)
        }
        else if (currentPage === maxPages - 1) {
            console.log("Put start element at end.");
            let startElement = mappedChildren.shift()
            mappedChildren.push(startElement)
        }

    }, [currentPage, mappedChildren, maxPages])

    reorderChildren(currentPage, mappedChildren);
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const autoChange = async () => {
        await delay(15000);
        changePage(currentPage + 1);
      };

    autoChange();
    // useEffect(() => {
    //     reorderChildren();
    //     console.log("Ran");
    // }, [reorderChildren]);


    const pageCircles = mappedChildren.map((child, index) => (
        <button 
        
    key={index}
    className='h-[30%] aspect-square flex justify-center items-center' onClick={() => changePage(index)}>
            <div className={` ${currentPage === index ? "bg-white  h-[80%]" : "bg-white/50  h-[60%]" } aspect-square rounded-full transition-all`}></div>

        </button>
    ));

 
    return (
        <div className="flex  h-[500px]  max-w-[1500px] w-full overflow-hidden relative shrink-0    ">
            {/* <img src={require("../Images/Forest.jpeg")} alt="" className="bg-cover h-full left-1/2 -translate-x-1/2 object-cover absolute  brightness-[50%] blur-[2px]"></img> */}
            <div className="flex justify-between h-full w-full z-10  items-center flex-col">
                
            <div className='h-[10%] w-full  flex justify-center items-center gap-2'>
                {pageCircles}
            </div>
            <div className='h-[90%] w-full  flex'>
                {mappedChildren}
            </div>
                
                {/* LEFT */}
                {/* <Clicker direction="left" onClick={() => {changePage("left"); reorderChildren()}}></Clicker> */}

                {/* CENTER */}
                {/* <Panel mappedChildren={mappedChildren} pageCircles={pageCircles}></Panel> */}

                {/* RIGHT */}
                {/* <Clicker direction="right" onClick={() => {changePage("right"); reorderChildren()}}></Clicker> */}

            </div>
        </div>

    
    )
}