import './App.css';
import Home from './Pages/Home.js';

function App() {
  return (
    <Home></Home>

  );
}

export default App;
