import { gsap } from "gsap";
import { useEffect, useRef } from "react";


export default function Card(props) {

    const {children,  focused=false, name, quote, link, color} = props;

    
    let animationRef = useRef();

    useEffect(() => {
        gsap.to(animationRef, {y:"10%", duration:3});
    }, []);

    return (
        <div
         className={` 
        flex relative
        justify-center items-center flex-col transition-opacity duration-50
        ${focused ? "h-full w-full" : "h-3/4 w-3/4 opacity-55 blur-[2px] hover:blur-[1px] hover:opacity-60"}
        rounded-[2.5rem]  
        `}>
            <img 
            
            ref={animationRef}
            src={require("../Images/Profiles/"+name+".jpg")} alt="" className={`shadow-black rounded-full  aspect-square overflow-hidden object-cover
            border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300  border-white absolute  z-30
            ${focused ? "top-[50%] -translate-y-1/2 left-[0%] w-1/3 " : "top-[60%] -translate-y-1/2 w-1/3"}
            `} ></img>
            
            <div className="flex justify-center items-center w-1/3 absolute  top-[71%] left-0 ">

                <div className=
                {`${focused ? "flex" : "hidden"}
                backdrop-blur-[5px] bg-gradient-to-r from-neutral-300/5 to-neutral-300/15 
                border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
                rounded-[1.5rem] bg-black
                text-3xl text-white px-4 py-1  w-fit z-20`} >
                    {name}
                </div>
            </div>


            <div className={`
            ${focused ? "flex" : "hidden"}
            backdrop-blur-[5px] bg-gradient-to-tr from-red-400/20 via-orange-300/20 to-pink-400/20 
            border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
            rounded-[1rem] 
            text-3xl text-white p-5  absolute top-[50%] -translate-y-1/2 right-[0%] 
            w-[65%] h-fit max-h-full
            justify-center items-center font-quote text-center
            `}>
                <p className="text-8xl absolute top-0 -left-[2%]">"</p>
                    {quote}
                <p className="text-8xl absolute -bottom-[0%] -right-[2%] rotate-180">"</p>
            </div>

            <div className="flex justify-center items-center w-[65%] absolute  top-[88%] right-0  ">

                <button 
                onClick={() => window.open(link)}
                className=
                {`${focused ? "flex" : "hidden"}
                backdrop-blur-[5px] bg-gradient-to-r from-neutral-300/5 to-neutral-300/15 
                border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
                rounded-[1.5rem] bg-black
                text-xl text-white px-4 py-1  w-fit z-20`} >
                    Read More
                </button>
            </div>



        </div> 
    )
}