import Button from "./Button";

export default function Header() {
    return (
        <div className="border-b-8 overflow-hidden w-[110%] h-[12%] min-h-[100px] bg-black/30 backdrop-blur-lg shadow-lg shadow-black/50  top-0 left-1/2 -translate-x-1/2 relative z-30">
            {/* <img src={require("../Images/Forest.jpeg")} alt="" className="bg-cover absolute top-[-75px] brightness-[50%] blur-[2px]"></img> */}
                
            <div className="w-full h-full flex items-center justify-start pr-[10%] gap-20">
                <img src={require("../Images/Logo.png")} alt="" className="h-[80%] pl-[8%] relative"></img>
                <Button text="Enter" symbol="arrow">Enter  </Button>
                <div className="hidden flex-col items-center relative lg:flex">
                    <p className="text-xl text-slate-200">Discover Everlasting Happiness, Love, Peace & Freedom</p>
                    <p className="text-4xl text-white">Without Suffering Ever Again</p>

                </div>

            </div>
        </div>
    
    )
}