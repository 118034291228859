import Carousel from "../Components/Carousel";
import Header from "../Components/Header";

export default function Home() {

    let testimonials =  [

        {
            name:`Matteo`,
            quote:`I immediately felt connected, and I could see myself in everything that everyone was sharing, making me realize that I'm not alone on this path.`,
            link:`https://www.skool.com/truthcommunity/my-first-satsang`,
            testimonial:`Last week I had my first Satsang. I must admit that I was feeling very nervous and didn't know if I would be able to join due to fear, but my intuition was pushing me loudly to be there. I'm glad I listened to it.
            I found a loving community to which I immediately felt connected, and I could see myself in everything that everyone was sharing, making me realize that I'm not alone on this path, that I'm not losing my mind (as I had started to believe), and that what we experience is really wonderful and natural.
            To anyone that could be experiencing fear about joining, I kindly invite you to take a leap of faith. You don't have to believe anything in particular, just let yourself be and enjoy the experience.
            What if something wonderful happens?`
        },

        {
            name:`Justin`,
            quote:`In this journey whenever I was met with confusion, fear, or doubt with where I was going with all of this, it's comforting to know that you’re in a legit environment and guidance.`,
            link:`https://www.skool.com/truthcommunity/discovering-our-true-nature`,
            testimonial:`Thanks Mitch and I’m so grateful for the pointers youve given in your videos and guidances. Time and time again reflecting us back to our true essence is really the love and patience you have for each of us.
            In this journey whenever I was met with confusion, fear, doubt with where I was going with all of this, its comforting to know that you’re in a legit environment and guidance.
            The way you express these pointers are easy for us to relax into our true nature and exposing any false identification that we have been holding on to, many times unnoticed or that we may not be aware of.
            Thank you once again for all that you do for this amazing community 🙏☀️❤️ Wishing everyone here a “truthful” and wonderful journey`
        },

        {
            name:`Reema`,
            quote:`I had many heartfelt conversations with Mitch in the last couple of weeks that have immensely helped me in my overall transformation and in understanding of my True Nature.`,
            link:`https://www.skool.com/truthcommunity/beyond-grateful`,
            testimonial:`It was not so long ago that I joined this community. I was confused and a fearful person. The only thing I knew for sure was that Mitch is genuine because my heart would resonate so well with all his pointers and guidance. 
            I had many heartfelt conversations with Mitch in the last couple of weeks that have immensely helped me in my overall transformation and in understanding of my True Nature. 
            I often fell short of words to express my Gratitude for him but I still will always be Grateful. 
            Lots of Love and Blessings to you Mitch.❤️`
        },

        {
            name:'Birgit',
            quote:`Since then I rest as pure being, stillness and silence, enjoy every now moment as it is and embrace everything that happens with pure unconditional love.`,
            link:`https://www.skool.com/truthcommunity/my-journey`,
            testimonial:`Hello everyone! After my near death experience four years ago, being in the light and experiencing God's eternal love and coming back into the body and being as pure love and peace after the artificial coma for one month, 
            the mind activated again and i back then knew, that the only thing i want is come back Home to my True nature. After recovering for many months, i started watching Eckhard Tolle,Rupert Spira, Anna Brown and so on. And a few months ago i found Mitch. 
            I have never been on a live Zoom guidance yet, but i have watched a lot of his videos, guidances and meditations and have recognized, that i am not the little "me" in my mind, the construct of thoughts, beliefs and conditionings, 
            that i have believed in throughout this life as the character of Birgit. When that was seen as the awareness that i truly am, i couldn't believe it anymore and didn't give these thoughts any attention/energy anymore and the mind got more quiet each day 
            until one day it was completely gone, totally quiet and peaceful. Since then i rest as pure being, stillness and silence, enjoy every now moment as it is and embrace everything that happens with pure unconditional love, 
            because it is all God and it is all perfect. When emotions arise, i just see them and let them flow through. Life is living through my body and lets go every moment on itself, i am just being, enjoying and trusting. 
            Everything is really light and easy now, no more worries or suffering. I really want to encourage everyone, not to give up, it is all worth it. And as Mitch told me, it takes "time" to brake out of the cocoon and become the beautiful butterfly, 
            although we always have been this beautiful butterfly, we just forgot it. Lots of love ❤️❤️❤️`
        }



    ]

    return (
        <div className="w-full h-full absolute bg-black overflow-hidden z-[-20]">
            <img src={require("../Images/Forest.jpeg")} alt="" className="bg-cover h-full left-1/2 -translate-x-1/2 object-cover absolute  brightness-[75%] blur-[2px] saturate-[1] z-[-10]"></img>

            <Header></Header>
            <div className=" py-[50px]  h-[88%] flex flex-row items-center justify-center   shrink-0">
                {/* <Carousel>
                    <div className="flex flex-col items-center justify-start gap-5 shrink-0">
                        <img src={require("../Images/Mitch.png")} alt="" className=" shadow-black rounded-full h-full border-[2px] border-white bg-black "></img>
                        <div className="flex flex-col items-center">
                            <p className="text-xl text-slate-200">guided by</p>
                            <p className="text-4xl text-white">Mitch Sanker</p>

                        </div>
                    </div>   
                    <img src={require("../Images/Overview.png")} alt="" className="max-w-[750px]  rounded-3xl h-full border-[2px] border-white bg-black shrink-1 block"></img>

                </Carousel>     */}
                
                <div className={` w-[30%] h-full relative flex flex-col justify-center items-center gap-3`}>
                    <div className=
                    {`
                    backdrop-blur-[5px] bg-gradient-to-r from-neutral-300/5 to-neutral-300/15 
                    border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
                    rounded-[1.5rem] bg-black
                    text-3xl text-white px-4 py-1  w-fit z-20 text-center`} >
                        Next Guidance Session:<br></br>Thursday, 8pm EST
                    </div>
                    <div className=
                    {`
                    backdrop-blur-[5px] bg-gradient-to-r from-neutral-300/5 to-neutral-300/15 
                    border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
                    rounded-[1.5rem] bg-black
                    text-3xl text-white px-4 py-1  w-fit z-20 text-center`} >
                        Next Free Guidance Session:<br></br>Monday, 9am EST
                    </div>
                    <img src={require("../Images/Profiles/Mitch.png")} alt="" className=" shadow-black rounded-full h-1/4 border-[2px] border-white  "></img>
                    <div className=
                    {`
                    backdrop-blur-[5px] bg-gradient-to-r from-neutral-300/5 to-neutral-300/15 
                    border-[8px] border-l-neutral-50 border-r-neutral-400  border-y-neutral-300 
                    rounded-[1.5rem] bg-black
                    text-3xl text-white px-4 py-1  w-fit z-20 text-center`} >
                        Guided by<br></br>Mitch Sanker
                    </div>

                </div>
                <Carousel elements={testimonials}>

                </Carousel>

            </div>
        </div>
    )
}